import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import { t as tt } from 'i18next'
import Plyr from 'plyr-react'
import 'plyr-react/plyr.css'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import VideoIcon from 'src/assets/icons/customIcons/Video'
import DownloadIcon from 'src/assets/icons/customIcons/page-icons/download'
import InfoIcon from 'src/assets/icons/customIcons/page-icons/info'
import ViewIcon from 'src/assets/icons/customIcons/page-icons/view'
import { checkVimeoLink, checkYoutubeLink, errorMessage, formatFileSize, shortenString } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import FileIcon from 'src/views/components/FileIcon'
import LinkIconComponent from 'src/views/components/LinkIcon'
import Loader from 'src/views/components/Loader'
import { MediaFilesDownload } from 'src/views/components/MediaFilesDownload'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import PopoverComponent from 'src/views/components/PopoverComponent'
import UserPhoto from 'src/views/components/UserPhoto'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentCourseMaterialsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:materials')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [openMaterial, setOpenMaterial] = React.useState<null | Api.CourseFile>(null)
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!
  const query = searchParams.get('query')
  const { id } = useParams()
  const t = useTranslatable()
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const theme = useTheme()
  const headers = useAuthenticatedHeaders()
  const {
    data: materials,
    isValidating: materialsPending,
    error: materialsError,
  }: APIResponse<Api.getStudentCoursesMaterialsOk | null, Api.getStudentCoursesMaterialsErr> = useApi({
    endpoint: Api.getStudentCoursesMaterials,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            search: query,
          },
          page: parseInt(page),
          perPage: parseInt(perPage),
        },
        args: {
          id: id!,
        },
      }),
      [headers, id, page, query, perPage]
    ),
    suspense: false,
  })

  const downloadFile = React.useCallback(
    async (id: string) => {
      const result = await Api.getMediaFilesDownload({
        headers,
        args: {
          id,
        },
      })
      // @ts-expect-error todo fix issue with types
      setOpenMaterial(result)
    },
    [headers]
  )

  return (
    <>
      <div className="w-full flex-auto p-5 px-0">
        <Form
          initialValues={{ query: query ?? '' }}
          onSubmit={async (value) => setSearchParams({ query: value.query })}
          classNames={{ form: 'flex flex-wrap md:!flex-nowrap justify-between' }}
        >
          <div className="w-full">
            <TextInput
              type="text"
              name="query"
              placeholder={t('common:enter_your_keyword')}
              searchField
              disabled={materialsPending}
              data-testid="searchMaterialsInput"
            />
          </div>
          <Button
            variant="red"
            type="submit"
            className="ml-0 w-[120px] md:ml-4"
            disabled={materialsPending}
            data-testid="searchMaterialsButton"
          >
            {t('common:search')}
          </Button>
        </Form>
        <div className="w-full">
          {(() => {
            if (
              (materialsError != null && !materialsPending && materials === undefined) ||
              ((materials?.data.length ?? 0) === 0 && !materialsPending)
            ) {
              return (
                <NoContent
                  header={t('error:records_not_found')}
                  subHeader={materialsError && errorMessage(materialsError)}
                  image={Warning}
                  marginTop="5"
                />
              )
            } else if (materials?.meta == null || materialsPending) {
              return <Loader className="m-auto flex" />
            }

            return (
              <>
                <PaginationTop pagination={materials.meta.pagination} />
                <Table.Table className="mt-7">
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th scope="col" />
                      <Table.Th scope="col">{t('common:name')}</Table.Th>
                      <Table.Th scope="col" className="text-center">
                        {t('common:size')}
                      </Table.Th>
                      <Table.Th scope="col">{t('lecturer:lecturer')}</Table.Th>
                      <Table.Th scope="col" />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {materials.data.map((material) => (
                      <Table.Tr key={material.id} data-testid={`material-${material.id}`}>
                        <Table.Td className="pl-3 align-middle">
                          {material.url != null ? (
                            <LinkIconComponent url={material.url} />
                          ) : (
                            <FileIcon type={material.mediaFile?.extension} />
                          )}
                        </Table.Td>

                        <Table.Td className="align-middle" data-testid="mediaFileTitle">
                          {material.url != null ? material.name : material.mediaFile?.title}
                        </Table.Td>
                        <Table.Td className="whitespace-nowrap">
                          <span data-testid="mediaFileSize">
                            {material.mediaFile != null ? formatFileSize(material.mediaFile.size) : '—'}
                          </span>
                        </Table.Td>
                        <Table.Td className="whitespace-nowrap pr-4 align-middle">
                          <UserPhoto className="scale-[0.7]" user={material.user} />{' '}
                          <Link
                            to={`/${locale}/users/${material.user!.uid}`}
                            className="ml-2 whitespace-nowrap align-middle text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                            data-tooltip-id={`${material.id}-lecturer`}
                            data-testid="userFullName"
                          >
                            {material.user?.fullName}
                          </Link>
                          <Tooltip
                            id={`${material.id}-lecturer`}
                            place="top"
                            variant={theme === 'dark' ? 'dark' : 'light'}
                            className="m-0 p-1"
                          >
                            <p className="mb-0">{t('lecturer:lecturers_page')}</p>
                          </Tooltip>
                        </Table.Td>
                        <Table.Td className="flex w-full justify-end">
                          <div className="flex w-[130px] flex-nowrap justify-between px-4" data-testid="options">
                            <button
                              onClick={() => {
                                if (material.mediaFile != null) {
                                  void downloadFile(material.mediaFile.id)
                                } else {
                                  setOpenMaterial(material)
                                }
                              }}
                              className={classNames(
                                'flex h-7 w-7 items-center justify-center rounded-full hover:bg-[#dedede]',
                                {
                                  'cursor-not-allowed opacity-50': !material.isViewableInWeb,
                                }
                              )}
                              disabled={!material.isViewableInWeb}
                              data-testid="viewMaterialButton"
                            >
                              <ViewIcon />
                            </button>

                            {material.mediaFile != null && (
                              <MediaFilesDownload
                                mediaFileId={material.mediaFile.id}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-testid="downloadMaterialButton"
                                className="flex size-7 items-center justify-center rounded-full p-1 hover:bg-table-icon-hover"
                              >
                                <DownloadIcon />
                              </MediaFilesDownload>
                            )}
                            {material.url != null && (
                              <a
                                href={material.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex size-7 items-center justify-center rounded-full hover:bg-table-icon-hover"
                                data-testid="linkMaterialButton"
                              >
                                <LinkIconComponent url={material.url} />
                              </a>
                            )}

                            <PopoverComponent
                              label={<InfoIcon />}
                              labelClassName={classNames(
                                'h-7 w-7 rounded-full hover:bg-[#dedede] flex justify-center items-center',
                                {
                                  disabled: material.mediaFile == null,
                                }
                              )}
                              disabled={material.mediaFile == null}
                            >
                              <p data-testid="materialTitle">
                                <strong>{t('common:title')}</strong> {material.mediaFile?.title}
                              </p>
                              <p data-testid="materialType">
                                <strong>{t('common:type')}</strong> {material.mediaFile?.mimeType}
                              </p>
                              <p data-testid="materialExtension">
                                <strong>{t('common:extension')}</strong> {material.mediaFile?.extension}
                              </p>
                              <p data-testid="materialSize">
                                <strong>{t('common:size')} </strong>
                                {material.mediaFile != null && formatFileSize(material.mediaFile.size)}
                              </p>
                              <p className="mb-0" data-testid="materialDate">
                                <strong>{t('common:date')}</strong>{' '}
                                {material.mediaFile != null &&
                                  format(parseISO(material.mediaFile.createdAt), 'd MMMM yyyy, HH:mm', {
                                    locale: dateLocale,
                                  })}
                              </p>
                            </PopoverComponent>
                          </div>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table.Table>
                <PaginationBottom pagination={materials.meta.pagination} />
              </>
            )
          })()}
        </div>
      </div>
      {openMaterial != null && (
        <Modal
          title={
            openMaterial?.url != null ? (
              <a href={openMaterial.url} target="_blank" rel="noreferrer">
                {shortenString(openMaterial.url, 40)}
              </a>
            ) : (
              shortenString(openMaterial?.mediaFile?.title, 40)
            )
          }
          isModalOpen={openMaterial != null}
          onClose={() => setOpenMaterial(null)}
          icon={<VideoIcon width="24" height="24" />}
        >
          {((): JSX.Element | null => {
            // poor man's do expression
            if (openMaterial.mediaFile != null) {
              if (/^(video|audio)\//.test(openMaterial.mediaFile.mimeType)) {
                return (
                  <Plyr
                    source={{
                      // TODO audio არ მუშაობს კარგად
                      // type: openMaterial.mimeType.startsWith('video/') ? 'video' : 'audio',
                      type: 'video',
                      sources: [
                        {
                          provider: 'html5',
                          src: openMaterial.url!,
                        },
                      ],
                    }}
                    options={{
                      controls: [
                        'play-large',
                        'play',
                        'progress',
                        'current-time',
                        'mute',
                        'volume',
                        'settings',
                        'fullscreen',
                      ],
                      autoplay: true,
                    }}
                  />
                )
              }
              return (
                <div className="m-auto text-center" role="alert">
                  <p>{tt('common:unhandled_type', { type: openMaterial.mediaFile.mimeType })}</p>
                  <p className="mb-0">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={openMaterial.mediaFile.downloadEndpoint}
                      data-testid="modalMaterialDownloadLink"
                    >
                      {t('file:download_file')}
                    </a>
                  </p>
                </div>
              )
            }

            if (openMaterial?.url != null) {
              if (checkYoutubeLink(openMaterial.url)) {
                return (
                  <Plyr
                    source={{
                      type: 'video',
                      sources: [
                        {
                          provider: 'youtube',
                          src: openMaterial.url,
                        },
                      ],
                    }}
                    options={{
                      controls: [
                        'play-large',
                        'play',
                        'progress',
                        'current-time',
                        'mute',
                        'volume',
                        'settings',
                        'fullscreen',
                      ],
                      autoplay: true,
                    }}
                  />
                )
              }

              if (checkVimeoLink(openMaterial.url)) {
                return (
                  <Plyr
                    source={{
                      type: 'video',
                      sources: [
                        {
                          provider: 'vimeo',
                          src: openMaterial.url,
                        },
                      ],
                    }}
                    options={{
                      controls: [
                        'play-large',
                        'play',
                        'progress',
                        'current-time',
                        'mute',
                        'volume',
                        'settings',
                        'fullscreen',
                      ],
                      autoplay: true,
                    }}
                  />
                )
              }

              return (
                <div className="m-auto border border-red-400 bg-red-100 text-center text-red-700" role="alert">
                  <p>{t('error:could_not_handle_url')}</p>
                  <p className="mb-0">
                    <a target="_blank" rel="noopener noreferrer" href={openMaterial.url} data-testid="modalMaterialUrl">
                      {openMaterial.url}
                    </a>
                  </p>
                </div>
              )
            }

            return null
          })()}
        </Modal>
      )}
    </>
  )
}
