import fullcalendarDaygrid from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import { format } from 'date-fns'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import CalendarIcon from 'src/assets/icons/customIcons/page-icons/CalendarIcon'
import { formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useFullCalendarLocale, useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import CalendarPopoverComponent from 'src/views/components/CalendarPopoverComponent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentCalendarPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('calendar:calendar')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentCalendar,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const calendarItems = data
  const t = useTranslatable()
  const fullCalendarLocale = useFullCalendarLocale()
  const dateLocale = useDateLocale()
  const { pathname } = useLocation()

  const breadcrumbsItems = [{ page: `${t('calendar:calendar')}`, path: pathname }]

  return (
    <Section title={t('calendar:calendar')} icon={<CalendarIcon />} breadcrubms={breadcrumbsItems}>
      <FullCalendar
        events={calendarItems.map((calendarItem) => {
          const start =
            calendarItem.eventType !== 'schedule'
              ? format(new Date(formatDatelocale(calendarItem.startDate) as Date), 'yyyy-MM-dd')
              : format(new Date(calendarItem.startDate), 'yyyy-MM-dd')
          const title =
            calendarItem.eventType !== 'schedule'
              ? calendarItem.title
              : format(new Date(calendarItem.startDate), 'HH:mm') + ' ' + calendarItem.title
          return {
            color:
              calendarItem.eventType === 'holiday'
                ? '#b94a48'
                : calendarItem.eventType === 'schedule'
                  ? '#FFB752'
                  : '#3A87AD',
            start,
            title,
            eventId: calendarItem.id,
            eventType: calendarItem.eventType,
          }
        })}
        initialView="dayGridMonth"
        eventClassNames="day-event"
        buttonText={{
          today: t('calendar:today'),
          list: t('common:day'),
        }}
        displayEventTime={false}
        plugins={[fullcalendarDaygrid, listPlugin]}
        headerToolbar={{
          left: 'dayGridMonth,listWeek,today',
          right: 'prev,title,next',
        }}
        height="auto"
        titleFormat={({ date }) => {
          return format(
            new Date(date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond),
            'LLLL yyyy',
            { locale: dateLocale }
          )
        }}
        dayHeaderFormat={({ date }) => {
          return format(
            new Date(date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond),
            'EEE',
            { locale: dateLocale }
          )
        }}
        views={{
          list: {
            eventContent: function (arg) {
              return (
                <CalendarPopoverComponent placement="top" label={arg.event.title}>
                  {arg.event.title}
                </CalendarPopoverComponent>
              )
            },
          },
        }}
        dayMaxEvents={2}
        locale={fullCalendarLocale}
        customButtons={{
          right: {
            text: t('calendar:today'),
          },
        }}
        eventContent={({ event }) => (
          <CalendarPopoverComponent placement="top" label={event.title}>
            {event.title}
          </CalendarPopoverComponent>
        )}
      />
    </Section>
  )
}
