import { formatDistance } from 'date-fns'
import { FormikConsumer } from 'formik'
import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { type CoursePostComment } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'

import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import UserPhoto from 'src/views/components/UserPhoto'

interface Props {
  post: Api.CoursePost
  setCommentsCount: React.Dispatch<React.SetStateAction<number>>
}

export default function Comments({ post, setCommentsCount }: Props): JSX.Element {
  const locale = useLocale()
  const [currentReplies, setCurrentReplies] = React.useState<Record<string, string>>({})
  const t = useTranslatable()
  const localeDate = useDateLocale()
  const auth = useAuth()
  const { id } = useParams()
  const headers = useAuthenticatedHeaders()

  const { data: comments, mutate: fetchComments } = useApi({
    endpoint: Api.getStudentCoursePostComments,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
          postId: post.id,
        },
      }),
      [headers, id, post.id]
    ),
  })

  const postComment = React.useCallback(
    async (body: string, commentId?: string) => {
      try {
        // const requestbody: Api.postStudentCoursesPostsCommentsParams['body'] = { body }
        // if (commentId != null) {
        //   requestbody.replyCommentId = commentId
        // }
        await Api.postStudentCoursesPostsComments({
          headers,
          args: {
            id: id!,
            postId: post.id,
          },
          body: {
            body,
            replyCommentId: commentId,
          },
        })
        void fetchComments()
        setCommentsCount((prev) => prev + 1)
        toast.success(t('discussion:comment_successfully_added'))
      } catch (error) {
        toast.error(t('discussion:comment_not_added'))
      }
    },
    [fetchComments, headers, id, post.id, setCommentsCount, t]
  )

  return (
    <ul className="my-2 w-full list-none pl-[35px]" data-testid="commentsList">
      {comments.map((comment: CoursePostComment) => (
        <li key={comment.id} data-testid={`comment-${comment.id}`} className="comment">
          <div className="my-2 flex w-full">
            <div className="mt-2" data-testid="parentUserPhoto">
              <UserPhoto user={comment.author} />
            </div>
            <div className="ml-2 w-full">
              <div className="rounded-card bg-card">
                <div className="px-3 py-2">
                  <div>
                    <Link
                      className="font-bold dark:text-white"
                      to={`/${locale}/users/${comment.author!.uid}`}
                      data-testid="authorName"
                    >
                      {comment.author!.fullName}
                    </Link>
                  </div>
                  <div className="my-2 text-sm dark:text-white" data-testid="commentContent">
                    {comment.body}
                  </div>
                </div>
              </div>
              <div className="ml-3 mt-1 text-sm text-captionColor">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentReplies((prev) => ({ ...prev, [comment.id]: comment.author!.fullName }))
                  }}
                  data-testid="commentReplyButton"
                >
                  {t('discussion:reply')}
                </button>
                {' ∙ '}
                <button onClick={(e) => e.preventDefault()} data-testid="commentCreationDate">
                  <span>
                    {formatDistance(new Date(comment.createdAt), new Date(), {
                      addSuffix: true,
                      locale: localeDate,
                    })}
                  </span>
                </button>
                {comment.authorGroup !== undefined ? (
                  <>
                    {' ∙ '}
                    <span className="ml-1 rounded-md bg-card p-1 text-caption" data-testid="authorGroup">
                      {comment.authorGroup.name}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {comment.children !== undefined ? (
            <div style={{ paddingLeft: 34 }}>
              <ul className="list-none" data-testid={`subCommentsList-${comment.id}`}>
                {comment.children.map((subComment) => (
                  <React.Fragment key={subComment.id}>
                    <li data-testid={`subComment-${subComment.id}`}>
                      <div className="my-2 flex">
                        <div className="mt-2">
                          <UserPhoto user={subComment.author} />
                        </div>
                        <div className="ml-2 w-full">
                          <div className="rounded-card bg-card">
                            <div className="px-3 py-2">
                              <div>
                                <Link
                                  className="font-bold dark:text-white"
                                  to={`/${locale}/users/${subComment.author!.uid}`}
                                  data-testid="subCommentAuthorName"
                                >
                                  {subComment.author!.fullName}
                                </Link>
                              </div>
                              <div className="my-2 text-sm dark:text-white" data-testid="subCommentContent">
                                {subComment.body}
                              </div>
                            </div>
                          </div>
                          <div className="ml-3 mt-1 text-sm text-captionColor">
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                setCurrentReplies((prev) => ({ ...prev, [comment.id]: subComment.author!.fullName }))
                              }}
                              data-testid="subCommentReplyButton"
                            >
                              {t('discussion:reply')}
                            </button>
                            {' ∙ '}
                            <button onClick={(e) => e.preventDefault()} data-testid="subCommentCreationDate">
                              <span>
                                {formatDistance(new Date(subComment.createdAt), new Date(), {
                                  addSuffix: true,
                                  locale: localeDate,
                                })}
                              </span>
                            </button>
                            {subComment.authorGroup !== undefined ? (
                              <>
                                {' ∙ '}
                                <span
                                  className="ml-1 rounded-md bg-card p-1 text-caption"
                                  data-testid="subCommentAuthorGroup"
                                >
                                  {subComment.authorGroup.name}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          ) : null}
          <CommentReply currentReplies={currentReplies} comment={comment} postReplyComment={postComment} />
        </li>
      ))}
      {auth.state === 'Authenticated' && (
        <li data-testid="commentForm" className="mt-2 flex">
          <div>
            <UserPhoto user={auth.user} />
          </div>
          <div className="ml-2 w-full">
            <div className="relative">
              <Form
                initialValues={{ body: '' }}
                onSubmit={async ({ body }, { resetForm }) => {
                  await postComment(body)
                  resetForm()
                }}
                enableReinitialize
                isConfirmable
              >
                <FormikConsumer>
                  {(formik) => (
                    <TextInput
                      name="body"
                      autoFocus
                      type="textarea"
                      className={`border border-primaryGray focus:border-buttonBlue focus:outline-none ${
                        formik.isSubmitting ? 'pointer-events-none opacity-50' : ''
                      }`}
                      placeholder={t('discussion:leave_a_comment_here')}
                      sendOrDelete
                      data-testid="commentInput"
                    />
                  )}
                </FormikConsumer>
              </Form>
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}

interface ICommentReplyProps {
  currentReplies: Record<string, string>
  comment: Api.CoursePostComment
  postReplyComment: (body: string, commentId: string) => Promise<void>
}

function CommentReply({ currentReplies, comment, postReplyComment }: ICommentReplyProps) {
  const auth = useAuth()
  const t = useTranslatable()
  const authorName = currentReplies[comment.id]
  if (!authorName) return null

  return (
    <div style={{ paddingLeft: 34 }} data-testid="commentFormReply">
      <div>
        <div className="mt-2 flex">
          <div>
            <UserPhoto user={auth.user} />
          </div>
          <div className="ml-2 w-full">
            <div className="relative">
              <Form
                initialValues={{ body: `@${authorName ?? ''} ` }}
                onSubmit={async ({ body }, { resetForm }) => {
                  await postReplyComment(body, comment.id)
                  resetForm()
                }}
                isConfirmable
                enableReinitialize
              >
                <FormikConsumer>
                  {(formik) => (
                    <TextInput
                      name="body"
                      autoFocus
                      type="textarea"
                      className={`border border-primaryGray focus:border-buttonBlue focus:outline-none ${
                        formik.isSubmitting ? 'pointer-events-none opacity-50' : ''
                      }`}
                      placeholder={t('discussion:leave_a_comment_here')}
                      sendOrDelete
                      data-testid="commentInputReply"
                    />
                  )}
                </FormikConsumer>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
