import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { useTheme } from 'src/state/providers/Theme'

HighchartsMore(Highcharts)
SolidGauge(Highcharts)

interface GaugeChartProps {
  minLimit: number[]
  score: number
  max: number
}

const GaugeChart: React.FC<GaugeChartProps> = ({ score, max, minLimit }) => {
  const minScore = minLimit[0] ?? 0
  const theme = useTheme()

  const options = {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'none',
      widht: '100',
      height: '100',
    },
    tooltip: {
      enabled: false,
    },
    title: null,
    credits: {
      enabled: false,
    },
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      startAngle: 0,
      endAngle: 360,
      background: {
        innerRadius: '100%',
        outerRadius: '0%',
        backgroundColor: theme === 'dark' ? '#25262a' : '#cccccc',
        borderWidth: 0,
        // shape: 'arc',
      },
    },

    plotOptions: {
      solidgauge: {
        innerRadius: '85%',
        dataLabels: {
          enabled: true,
          align: 'center',
          inside: true,
          backgroundColor: 'transparent',
          borderWidth: '0',
          borderColor: theme === 'dark' ? '#25262a' : '#454A64',

          outline: 'none',
          color: theme === 'dark' ? '#cccccc' : '#000',
          style: {
            fontWeight: '200',
            textOutline: 'none',
            fontSize: '13px',
            width: '25px',
          },
          y: -14,
        },
        // size: 80,
      },
    },
    yAxis: {
      labels: {
        enabled: false,
        style: {
          fontSize: '12px',
        },
      },
      minColor: 'red',
      maxColor: '#FFD76A',
      lineWidth: 0,

      stops: [
        [minScore / max - 0.0001, '#FFD76A'],
        [minScore / max, '#9ED995'],
      ],

      min: 0,
      max: max,
      gridLineColor: 'transparent',
      lineColor: 'transparent',
      minorTickLength: 0,
      tickPositions: minLimit,
      tickColor: '#454A64',
      tickPosition: 'inside',
      tickLength: '5',
      tickWidth: 1,
    },

    series: [
      {
        data: [score],
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} />
}

export default GaugeChart
