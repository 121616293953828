export default function Achievement(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-testid="achievementIcon">
      <g id="achievment_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          stroke="#707070"
          className="fill-primaryWhite"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          className="fill-svgColor"
          id="Polygon_16"
          data-name="Polygon 16"
          d="M5.807.564a.2.2,0,0,1,.379,0L7.452,4.328a.2.2,0,0,0,.184.136l3.815.1a.2.2,0,0,1,.12.356L8.526,7.376a.2.2,0,0,0-.067.21L9.547,11.44a.2.2,0,0,1-.31.216L6.114,9.4a.2.2,0,0,0-.234,0l-3.123,2.26a.2.2,0,0,1-.31-.216L3.535,7.586a.2.2,0,0,0-.067-.21L.423,4.922a.2.2,0,0,1,.12-.356l3.815-.1a.2.2,0,0,0,.184-.136Z"
          transform="translate(310.977 417.399)"
        />
        <path
          className="fill-svgColor"
          id="Subtraction_236"
          data-name="Subtraction 236"
          d="M1.758,7.5H0V0L3.75,1.5,7.5,0V7.5H5.746V6h.028V3L3.75,3.73,1.742,3V6h.015V7.5Z"
          transform="translate(327.495 435.25) rotate(150)"
        />
        <path
          className="fill-svgColor"
          id="Subtraction_237"
          data-name="Subtraction 237"
          d="M1.756,0H0V7.5L3.748,6,7.5,7.5V0H5.744V1.5h.028v3L3.748,3.768,1.741,4.5v-3h.015V0Z"
          transform="translate(310.204 428.757) rotate(30)"
        />
        <g
          id="Ellipse_1016"
          data-name="Ellipse 1016"
          transform="translate(308 415)"
          fill="none"
          className="stroke-svgColor"
          strokeWidth="2"
        >
          <circle cx="9" cy="9" r="9" stroke="none" />
          <circle cx="9" cy="9" r="8" fill="none" />
        </g>
      </g>
    </svg>
  )
}
