import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentBillingInstructionPage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <SuspenseWrapper title={t('billingLayout:payment_instruction')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const userState = useUserState()
  const { data } = useApi({
    endpoint: Api.getStudentBillingInstructions,
    params: React.useMemo(() => ({ headers }), [headers]),
    shouldFetch: userState.data?.regime.functionalIsLimited === false,
  })
  if (data == null) return null
  return (
    <div className="syllabus-content billing-instruction text-title" dangerouslySetInnerHTML={{ __html: data.html }} />
  )
}
