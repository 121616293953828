import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray, useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api'
import { DownloadIcon } from 'src/assets/icons/customIcons/Download'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatDate, formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { MediaFilesDownload } from 'src/views/components/MediaFilesDownload'
import Checkbox from 'src/views/components/forms/formik/Checkbox'
import DateInput from 'src/views/components/forms/formik/DateInput'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Swal from 'sweetalert2'
import SwitchButton from './SwitchButton'

interface FormikValues extends Api.ResumeEducation {
  readonly localFile: {
    readonly id: string | null
    readonly uploading: boolean
  }
}

export default function StudentResumeEducationPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:education')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="education" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

async function PageContentData(
  headers: Api.getStudentResumeEducationsParams
): Promise<readonly [Api.getStudentResumeEducationsOk, Api.getCountriesOk, Api.getStudentResumeUniversitiesOk]> {
  const data1 = Api.getStudentResumeEducations(headers)
  const data2 = Api.getCountries(headers)
  const data3 = Api.getStudentResumeUniversities(headers)

  return await Promise.all([data1, data2, data3])
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { data, mutate } = useApi({
    endpoint: PageContentData,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  const form = data?.[0]
  const countries = data?.[1]
  const universities = data?.[2]

  const t = useTranslatable()

  const onSubmit = React.useCallback(
    async (values: { data: readonly FormikValues[] }) => {
      await Api.postStudentResumeEducations({
        headers,
        body: {
          data: values.data.map((education) => ({
            startDate: formatDate(education.startDate)!,
            endDate: formatDate(education.endDate)!,
            isCurrent: education.isCurrent,
            countryId: education.countryId,
            cityId: education.cityId,
            universityId: education.universityId,
            qualification: education.qualification,
            document: {
              type: education.document.type != null ? Api.ResumeDocumentTypeEncoder(education.document.type) : null,
              fileUid:
                education.localFile != null
                  ? education.localFile.id
                  : education.educationFile != null
                    ? education.educationFile.id
                    : null,
            },
          })),
        },
      })
      toast.success(t('person:profile_updated_successfully'))

      mutate()
    },
    [headers, mutate, t]
  )

  async function handleDelete(index: number, remove: (index: number) => void): Promise<void> {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      remove(index)
    }
  }

  const initialValues = React.useMemo(
    () => ({
      data: form?.map((entity) => ({
        ...entity,
        localFile: {
          id: null,
          uploading: false,
        },
      })),
    }),
    [form]
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      classNames={{ form: 'w-full' }}
      enableReinitialize
      isConfirmable
    >
      <FormError />
      <FieldArray name="data">
        {({ remove, push, form }: any) => (
          <>
            {form.values.data.map((item: FormikValues, index: number) => (
              <div key={index} className="my-2 w-full rounded-card bg-card p-6 pt-4">
                <div className="flex w-full justify-end" title={t('common:delete')}>
                  <button
                    onClick={() => void handleDelete(index, remove)}
                    type="button"
                    className="rounded-full p-2 hover:bg-hover-icon"
                  >
                    <DeleteIcon />
                  </button>
                </div>
                <FormEntity item={item} index={index} countries={countries} universities={universities} />
              </div>
            ))}
            <Button
              variant="blue"
              type="button"
              className="my-4 w-full"
              onClick={() => push(newEducation)}
              disabled={false}
            >
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
          </>
        )}
      </FieldArray>
      <FormSubmit
        classNames={{
          root: 'flex justify-end',
        }}
      />
    </Form>
  )
}

const newEducation = {
  isCurrent: false,
  startDate: '',
  endDate: null,
  qualification: '',
  countryId: '',
  universityId: '',
  cityId: '',
  document: {
    name: null,
    type: null,
    url: null,
  },
  localFile: {
    id: null,
    uploading: false,
  },
}

interface Props {
  item: FormikValues
  index: number
  countries: Api.getCountriesOk
  universities: Api.getStudentResumeUniversitiesOk
}

function FormEntity({ item, index, countries, universities }: Props): JSX.Element {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const [disabled, setDisabled] = React.useState(false)
  const formik = useFormikContext<{ data: Array<typeof newEducation> }>()

  const { data: cities, isValidating } = useApi({
    endpoint: Api.getStudentResumeCities,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            countryId: item.countryId,
          },
        },
      }),
      [headers, item.countryId]
    ),
    shouldFetch: item.countryId != null && item.countryId.length !== 0,
    suspense: false,
  })

  const handleDelete = React.useCallback(async (): Promise<void> => {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      void formik.setFieldValue(`data.${index}.localFile`, {
        id: null,
        uploading: false,
      })
      void formik.setFieldValue(`data.${index}.educationFile`, null)
    }
  }, [formik, index, t])

  return (
    <React.Fragment key={index}>
      <div className="mb-2 flex w-full justify-end" />
      <div className="my-1 flex flex-wrap items-start">
        <div className="flex w-full flex-wrap justify-between">
          <div className="mb-3 w-full md:w-[49%]">
            <DateInput
              labelAnimation
              required
              name={`data.${index}.startDate`}
              label={t('common:start_date')}
              maxDate={formatDatelocale(item.endDate)}
            />
          </div>
          <div className="flex w-full flex-wrap md:w-[49%] md:!flex-nowrap">
            <DateInput
              labelAnimation
              required={!item.isCurrent}
              name={`data.${index}.endDate`}
              label={t('common:end_date')}
              minDate={formatDatelocale(item.startDate)}
              disabled={item.isCurrent}
            />
            <div className="ml-1 mt-2 md:!ml-3 md:!mt-3">
              <Checkbox name={`data.${index}.isCurrent`} label={t('common:current')} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-wrap justify-between">
        <div className="mb-0 w-full min-w-[100px] sm:w-[49%] xl:!mb-3">
          <SelectInput
            name={`data.${index}.countryId`}
            options={countries?.map((item) => ({ value: item.id, label: item.name })) ?? []}
            label={t('common:country')}
            labelAnimation
            required
          />
        </div>
        <div className="w-full min-w-[100px] sm:w-[49%]">
          <SelectInput
            name={`data.${index}.cityId`}
            options={cities?.map((item) => ({ value: item.id, label: item.name })) ?? []}
            label={t('common:city')}
            disabled={isValidating}
            isLoading={isValidating}
            labelAnimation
            required
          />
        </div>
      </div>
      <div className="flex w-full flex-wrap justify-between">
        <SelectInput
          name={`data.${index}.universityId`}
          options={universities?.map((item) => ({ value: item.id, label: item.name })) ?? []}
          label={t('resume:university')}
          labelAnimation
          required
        />
      </div>
      <div className="flex w-full flex-wrap justify-between">
        <TextInput
          name={`data.${index}.qualification`}
          placeholder={t('resume:qualification_name')}
          type="text"
          searchField
          required
        />
      </div>
      <div className="flex w-full flex-wrap items-start justify-between">
        <div className="mb-3 mr-0 w-full min-w-[100px] md:mb-0 md:mr-2 md:w-[49%]">
          <SelectInput
            name={`data.${index}.document.type`}
            options={Api.ResumeDocumentTypeValues?.map((item) => ({ value: item, label: item })) ?? []}
            label={t('resume:diploma_certificate')}
            labelAnimation
          />
        </div>
        <div className="flex w-full min-w-[100px] flex-wrap justify-start md:w-[49%] md:justify-end">
          <FileInput
            disabled={disabled}
            fileText={item.educationFile != null ? t('file:change_file') : undefined}
            config={UploadConfigMap.studentResumeEducations}
            label={t('resume:diploma_certificate_scan')}
            onChange={(files) => {
              const newFileId = files[0]?.status === 'DONE' ? files[0].remoteFile.id : item.educationFile?.id
              if (files[0]?.status === 'UPLOADING') {
                setDisabled(true)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: null,
                  uploading: true,
                })
              }
              if (files[0]?.status === 'FAILED') {
                setDisabled(true)
              }
              if (item.educationFile?.id !== newFileId) {
                setDisabled(false)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: newFileId,
                  uploading: false,
                })
              }
              if (files.length === 0) {
                setDisabled(false)
                void formik.setFieldValue(`data.${index}.localFile`, {
                  id: null,
                  uploading: false,
                })
              }
            }}
          >
            {item.educationFile != null ? (
              <div className="flex items-center">
                <MediaFilesDownload
                  mediaFileId={item.educationFile.id}
                  style={{ zIndex: 0 }}
                  title={t('common:download')}
                >
                  <Button type="button" variant="blue" className="flex items-center">
                    <DownloadIcon color="white" />
                  </Button>
                </MediaFilesDownload>
                <Button
                  title={t('common:delete')}
                  variant="red"
                  className="ml-1 flex items-center"
                  type="button"
                  onClick={() => void handleDelete}
                  style={{ zIndex: 0 }}
                >
                  <DeleteIcon color="white" />
                </Button>
              </div>
            ) : undefined}
          </FileInput>
        </div>
      </div>
    </React.Fragment>
  )
}
