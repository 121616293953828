import { useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { formatFileSize } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { FileDone, type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileIcon from 'src/views/components/FileIcon'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import PopoverComponent from 'src/views/components/PopoverComponent'
import UserPhoto from 'src/views/components/UserPhoto'
import FormikTinyMce from 'src/views/components/forms/FormikTinyMce'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface CompetencyCriteria {
  criteriaId: number
  text: string
}

interface Competency {
  competencyId: number
  criterias: CompetencyCriteria[]
}

interface FormikValues {
  readonly isDraft: boolean
  readonly mentorId: string
  readonly text: string
  readonly id: string
  readonly files: readonly FileState[]
  readonly competencies: Competency[]
}

export default function StudentPortfolioWorkPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:assignment')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const params = useParams()
  const navigate = useNavigate()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)

  const section = useApi({
    endpoint: Api.getStudentPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const work = useApi({
    endpoint: Api.getStudentPortfolioSectionWork,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.workId!,
        },
      }),
      [headers, params.sectionId, params.workId]
    ),
    suspense: false,
  })

  const initialValues: FormikValues = {
    isDraft: true,
    id: work?.data?.id ?? '',
    mentorId: work?.data?.mentor?.id ?? '',
    text: work?.data?.text ?? '',
    competencies:
      work.data?.competencies?.map((v) => ({
        competencyId: Number(v.id),
        criterias:
          v.criterias?.map((criteria) => ({
            criteriaId: Number(criteria.id),
            text: criteria.text ?? '',
          })) ?? [],
      })) ?? [],
    files:
      work.data?.mediaFiles?.map((file) => ({
        status: 'DONE',
        remoteFile: file,
      })) ?? [],
  }

  const { pathname } = useLocation()

  const onSubmit = React.useCallback(
    async (values: FormikValues): Promise<void> => {
      const { mentorId, text, files, competencies, isDraft } = values
      const filteredCompetencies =
        section.data.workType === 'FORM_2'
          ? competencies.map(({ competencyId, criterias }) => ({
              competencyId,
              criterias: criterias.map(({ criteriaId, text }) => ({
                criteriaId,
                text,
              })),
            }))
          : competencies.map(({ competencyId }) => ({ competencyId }))

      if (isDraft) {
        await Api.putStudentPortfolioWork({
          headers,
          body: {
            mentorId,
            ...(text && { text }),
            competencies: filteredCompetencies,
            files: files.flatMap((e) => e.remoteFile?.id ?? []),
          },
          args: {
            id: params.sectionId!,
            workId: params.workId!,
          },
        })
      } else if (work.data?.status === 'STATUS_REJECTED') {
        await Api.patchStudentPortfolioWork({
          headers,
          args: {
            id: params.sectionId!,
            workId: params.workId!,
          },
          body: {
            ...(text && { text }),
            competencies: filteredCompetencies,
            files: files.map((e) => (e as FileDone).remoteFile.id),
          },
        })
      } else {
        await Api.postStudentPortfolioWork({
          headers,
          body: {
            mentorId,
            ...(text && { text }),
            competencies: filteredCompetencies,
            files: files.map((e) => (e as FileDone).remoteFile.id),
          },
          args: {
            id: params.sectionId!,
            workId: params.workId!,
          },
        })
      }

      toast.success(t('portfolio:assignment_submitted'))
      setSuccessfullySubmited(true)
    },
    [section.data.workType, work.data?.status, t, headers, params.sectionId, params.workId]
  )

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/student/portfolio/sections/${params.sectionId}/works`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/student/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/student/portfolio/sections/${params.sectionId}/works` },
    { page: `${t('portfolio:assignment')}`, path: pathname },
  ]
  return (
    <>
      <Section
        title={t('portfolio:portfolio_assignment')}
        icon={<Portfolio />}
        breadcrubms={breadcrumbsItems}
        rightElement={<BackButton link={`/${locale}/student/portfolio/sections/${params.sectionId}/works`} />}
      />
      {work.data?.status == 'STATUS_EVALUATED' || work.data?.status == 'STATUS_PENDING' ? (
        <div className="text-primaryTextColor">
          <div className="mb-6">
            <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:assignment_competencies') + `: `}</h2>
            {work.data.competencies !== null
              ? work.data.competencies?.map((competency) => (
                  <div key={competency.id} className="mb-2">
                    <div className="mt-2">
                      {competency.name}
                      <PopoverComponent>
                        <span className="mb-3 flex py-2" dangerouslySetInnerHTML={{ __html: competency.description }} />
                        <div className="mb-6">
                          <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
                            {t('portfolio:criterias')}:
                          </div>
                          {competency.criterias?.map((criteria) => (
                            <div key={`${competency.id}-${criteria.id}`} className="my-2">
                              <label className="mb-3 text-primaryTextColor">
                                <div className="my-">
                                  {criteria.name}
                                  <div>
                                    <span className="p-2" dangerouslySetInnerHTML={{ __html: criteria.description }} />
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </div>
                      </PopoverComponent>
                    </div>

                    {competency.criterias?.map((criteria) => (
                      <div key={`${competency.id}-${criteria.id}`} className="mb-5 mt-4 rounded-card bg-card px-4 py-1">
                        <div className="mb-2.5 mt-3 grid grid-cols-6">
                          <div className="col-span-1">
                            <label>{t('portfolio:criterion')}:</label>
                          </div>
                          <div className="col-span-5 float-left">
                            <div>{criteria.name}</div>
                          </div>
                        </div>
                        {criteria.text && (
                          <div key={`${competency.id}-${criteria.id}`} className="my-2.5 grid grid-cols-6">
                            <div className="col-span-1">
                              <label>{t('portfolio:description')}:</label>
                            </div>
                            <div className="col-span-5 float-left">
                              <div>{criteria.text}</div>
                            </div>
                          </div>
                        )}
                        {criteria.score?.score !== undefined && (
                          <div className="my-2.5 grid grid-cols-6">
                            <div className="col-span-1">
                              <label>{t('common:score')}:</label>
                            </div>
                            <div className="col-span-5 float-left">
                              <div>{criteria.score?.score}</div>
                            </div>
                          </div>
                        )}
                        {work.data?.status === 'STATUS_EVALUATED' && (
                          <div key={`${competency.id}-${criteria.id}`} className="my-2.5 grid grid-cols-6">
                            <div className="col-span-1">
                              <label>{t('portfolio:assignment_feedback')}:</label>
                            </div>
                            <div className="col-span-5 float-left">
                              <div> {criteria.score?.feedback}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))
              : null}
          </div>
          {work.data.text ? (
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:assignment_description') + `: `}</h2>
              <span dangerouslySetInnerHTML={{ __html: work.data.text }} />
            </div>
          ) : null}

          <div className="mb-6">
            <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:mentor') + `: `}</h2>
            {work.data.mentor != null ? (
              <div className="text-title">
                <UserPhoto className="scale-[0.7]" user={work.data.mentor} />
                <Link
                  to={`/${locale}/users/${work.data.mentor.uid}`}
                  className="ml-2 whitespace-nowrap align-middle !no-underline hover:underline"
                  data-tooltip-id={`${work.data.id}-lecturer`}
                >
                  <span className="ml-2">{work.data.mentor?.fullName}</span>
                </Link>
              </div>
            ) : (
              <span> - </span>
            )}
          </div>
          {work.data.mediaFiles !== undefined && work.data.mediaFiles.length > 0 ? (
            <div className="mb-8">
              <h2 className="works-section mb-3 border-b border-borderGrey py-2">{t('file:attached_files') + `: `}</h2>
              <div className="syllabus-content text-title">
                <div className="row-auto my-3">
                  <div className="col-auto mb-2">
                    {work.data.mediaFiles.map((file) => (
                      <div key={file.id} className="mr-4 flex">
                        <FileIcon type={file.extension} />{' '}
                        <a
                          href={`/${locale}/media-files/${file.id}/download`}
                          className="mx-2 dark:text-white"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {file.originalName}
                        </a>
                        <span className="text-sm text-captionColor">({formatFileSize(file.size)})</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mb-8">
            <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:assignment_status') + `: `}</h2>
            <div>
              {work.data.status === 'STATUS_EVALUATED' && (
                <span className="text-primaryTextColor">{t('portfolio:status_evaluated')}</span>
              )}
              {work.data.status === 'STATUS_PENDING' && <span>{t('portfolio:status_pending')}</span>}
            </div>
          </div>
          {work.data?.comment != null && work.data?.comment.length > 0 ? (
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:lecturer_comment') + `: `}</h2>
              <div className="text-title text-primaryTextColor">{work.data.comment}</div>
            </div>
          ) : null}
        </div>
      ) : work.data?.status == 'STATUS_DRAFT' || work.data?.status == 'STATUS_REJECTED' ? (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          isConfirmable={!successfullySubmited}
        >
          <FormError />
          <SuspenseWrapper>
            <FormContent work={work.data} section={section.data} />
          </SuspenseWrapper>
        </Form>
      ) : (
        <NoContent header={t('error:records_not_found')} image={Warning} />
      )}
    </>
  )
}

function FormContent({
  work,
  section,
}: {
  work: Api.StudentPortfolioUserWork
  section: Api.Section
}): React.ReactElement | null {
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const params = useParams()

  const mentors = useApi({
    endpoint: Api.getStudentPortfolioMentros,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  React.useEffect(() => {
    if (work?.text) {
      formik.setFieldValue('text', work.text)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sectionCompetencies = useApi({
    endpoint: Api.getStudentPortfolioCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  return (
    <div className="w-full rounded-card bg-card px-6 py-9">
      <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
        {t('portfolio:competence')}
      </div>
      {(() => {
        if (sectionCompetencies.data.data != null) {
          if (section.workType == 'FORM_2') {
            return sectionCompetencies.data.data.map((competencyItem, index) => (
              <div key={competencyItem.id} className="mb-6 rounded-sm border border-borderGrey p-4">
                <div className="mb-3 mr-4 flex items-center">
                  <label className="text-primaryTextColor" htmlFor={competencyItem.id}>
                    <span className="font-bold">
                      {competencyItem.name}
                      <PopoverComponent>
                        <span
                          className="mb-3 flex py-2"
                          dangerouslySetInnerHTML={{ __html: competencyItem.description }}
                        />
                        {competencyItem.criterias?.map((criteria) => (
                          <div key={`${competencyItem.id}-${criteria.id}`} className="my-2">
                            <label className="mb-3 text-primaryTextColor">
                              <div className="my-3">
                                {criteria.name}
                                <div>
                                  <span className="p-2" dangerouslySetInnerHTML={{ __html: criteria.description }} />
                                </div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </PopoverComponent>
                    </span>
                  </label>
                </div>
                <div className="flex">
                  {competencyItem.criterias?.map((criteria, criteriaIndex) => (
                    <div key={`${competencyItem.id}-${criteria.id}`} className="mb-3 mr-2 flex w-full">
                      <TextareaInput
                        label={criteria.name}
                        name={`competencies[${index}]criterias[${criteriaIndex}]text`}
                        rows={5}
                        required
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          } else {
            return (
              <div className="mb-6">
                {sectionCompetencies.data.data.map((c) => (
                  <span key={c.id} className="mb-3 mr-4 flex items-center">
                    <input
                      name="competencies"
                      type="checkbox"
                      id={c.id}
                      className="border border-tableBorderColor"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          formik.setFieldValue('competencies', [
                            ...formik.values.competencies,
                            {
                              competencyId: c.id,
                            },
                          ])
                        } else if (section.workType !== 'FORM_3') {
                          formik.setFieldValue(
                            'competencies',
                            formik.values.competencies.filter((v) => v.competencyId.toString() !== c.id)
                          )
                        }
                      }}
                      checked={
                        formik.values.competencies.some((v) => v.competencyId.toString() === c.id) ||
                        section.workType == 'FORM_3'
                      }
                      // disabled={section.workType == 'FORM_3'}
                    />
                    <label className="text-primaryTextColor" htmlFor={c.id}>
                      <span className="ml-2">
                        {c.name}
                        <PopoverComponent>
                          <span className="flex p-2" dangerouslySetInnerHTML={{ __html: c.description }} />
                          {(() => {
                            if (sectionCompetencies.data.data != null) {
                              return (
                                <div className="mb-6">
                                  <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
                                    {t('portfolio:criterias')}:
                                  </div>
                                  {sectionCompetencies.data.data.map((criterias) =>
                                    criterias.criterias?.map((criteria) => (
                                      <div key={`${c.id}-${criteria.id}`} className="my-2">
                                        <label className="mb-3 text-primaryTextColor">
                                          <div className="my-3">
                                            {criteria.name}
                                            <div>
                                              <span
                                                className="p-2"
                                                dangerouslySetInnerHTML={{ __html: criteria.description }}
                                              />
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    ))
                                  )}
                                </div>
                              )
                            }
                          })()}
                        </PopoverComponent>
                      </span>
                    </label>
                  </span>
                ))}
              </div>
            )
          }
        }
      })()}

      <div className="mb-6 text-primaryTextColor">
        <SelectInput
          placeholder={t('common:choose')}
          label={t('portfolio:mentor')}
          name="mentorId"
          options={mentors.data.map((m) => ({ label: `${m.firstName} ${m.lastName}`, value: m.id })) ?? []}
        />
      </div>
      {section.workType == 'FORM_2' ? null : (
        <div>
          <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
            {t('portfolio:add_assignment_description')}
          </div>
          <FormikTinyMce name="text" required />
        </div>
      )}

      <div className="my-6" style={{ zIndex: 0, maxWidth: 1065 }}>
        <FileInput name="files" multiple config={Api.UploadConfigMap.studentPortfolio(params.sectionId!)} />
      </div>

      <div className="mb-8">
        <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:assignment_status') + `: `}</h2>
        <div>
          {work.status === 'STATUS_DRAFT' && (
            <span className="text-primaryTextColor">{t('portfolio:status_draft')}</span>
          )}
          {work.status === 'STATUS_REJECTED' && (
            <span className="text-primaryTextColor">{t('portfolio:status_rejected')}</span>
          )}
        </div>
      </div>
      {work.comment != null && work.comment.length > 0 ? (
        <div className="mb-6">
          <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:lecturer_comment') + `: `}</h2>
          <div className="text-title text-primaryTextColor">{work.comment}</div>
        </div>
      ) : null}
      <div className="flex w-full justify-end">
        {work.manage?.canSaveAsDraft && work.status !== 'STATUS_REJECTED' && (
          <div className="mr-2 mt-10">
            <Button
              variant="red"
              disabled={!formik.isValid || !formik.dirty}
              onClick={(e) => {
                e.preventDefault()
                formik.setFieldValue('isDraft', true)
                formik.submitForm()
              }}
            >
              {t('common:save_as_draft')}
            </Button>
          </div>
        )}
        {work.manage?.canEditWork ||
        work.status === 'STATUS_REJECTED' ||
        (work.status === 'STATUS_DRAFT' && work.manage?.canEditWork === false) ? (
          <div className="ml-2 mt-10">
            <Button
              type="button"
              variant="red"
              onClick={(e) => {
                e.preventDefault()
                formik.setFieldValue('isDraft', false)
                formik.submitForm()
              }}
            >
              {t('common:submit')}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
