import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { Recommendations } from 'src/assets/icons/customIcons/recommendation'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentRecommendationsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('recommendation:recommendations')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!
  const { pathname } = useLocation()
  const result = useApi({
    endpoint: Api.getStudentRecommendations,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          page: parseInt(page),
          perPage: parseInt(perPage),
        },
      }),
      [headers, page, perPage]
    ),
  })
  const { data: recommendations, meta } = result.data

  const breadcrumbsItems = [{ page: `${t('recommendation:recommendations')}`, path: pathname }]

  return (
    <Section title={t('recommendation:recommendations')} icon={<Recommendations />} breadcrubms={breadcrumbsItems}>
      {recommendations.length > 0 ? (
        <>
          <PaginationTop pagination={meta.pagination} />

          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Td colSpan={4} />
              </Table.Tr>
              <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th>{t('common:text')}</Table.Th>
                <Table.Th>{t('common:author')}</Table.Th>
                <Table.Th>{t('common:date')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {recommendations.map((recommendation, i) => (
                <Table.Tr key={recommendation.id} data-testid={`recommendation/${recommendation.id}`}>
                  <Table.Td>{(meta.pagination.currentPage - 1) * meta.pagination.perPage + i + 1}</Table.Td>
                  <Table.Td>
                    <Link
                      className="hover:underline"
                      to={`/${locale}/student/recommendations/${recommendation.id}`}
                      data-testid="text"
                    >
                      {recommendation.textFragment}
                    </Link>
                  </Table.Td>
                  <Table.Td data-testid="author">{recommendation.author.fullName}</Table.Td>
                  <Table.Td data-testid="date">
                    {format(parseISO(recommendation.createdAt), 'd MMMM yyyy, HH:mm', {
                      locale: dateLocale,
                    })}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
          <PaginationBottom pagination={meta.pagination} />
        </>
      ) : (
        <NoContent
          header={t('error:recommendations_not_found')}
          image={Warning}
          subHeader={t('course:for_request_recommendation')}
        />
      )}
    </Section>
  )
}
