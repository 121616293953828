import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import Catalog from 'src/assets/icons/customIcons/page-icons/Catalog'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'

import { shortenString } from 'src/helpers/fns'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Loader from '../../../../components/Loader'
import NoContent from '../../../../components/NoContent'
import { PaginationBottom, PaginationTop } from '../../../../components/Pagination'

export default function StudentCatalogPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:courses_catalog')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const query = searchParams.get('query') ?? ''
  const page = searchParams.get('page') ?? '1'
  const perPage = searchParams.get('perPage') ?? '10'

  const fetching = useApi({
    endpoint: Api.getStudentCoursesCatalog,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          'filters[search]': query,
          page: parseInt(page),
          perPage: parseInt(perPage),
        },
      }),
      [headers, page, query, perPage]
    ),
  })
  const { data, meta } = fetching.data
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const { pathname } = useLocation()
  // const coursesPending = useStore(Api.getStudentCoursesCatalog.pending)
  if (userState.data == null) return null

  const breadcrumbsItems = [{ page: `${t('course:courses_catalog')}`, path: pathname }]

  return (
    <Section title={t('course:courses_catalog')} icon={<Catalog />} breadcrubms={breadcrumbsItems}>
      <Form
        initialValues={{ query }}
        onSubmit={async (value) => setSearchParams({ query: value.query }, { replace: true })}
        classNames={{ form: 'mb-2 flex flex-wrap md:!flex-nowrap justify-between' }}
      >
        <div className="w-full">
          <TextInput
            type="text"
            name="query"
            className="w-full"
            placeholder={t('common:enter_your_keyword')}
            searchField
            data-testid="query"
          />
        </div>
        <Button variant="red" className="ml-0 w-[120px] md:ml-4" type="submit">
          {t('common:search')}
        </Button>
      </Form>
      {data != null && data.length > 0 ? (
        <>
          <PaginationTop pagination={meta.pagination} />

          <Table.Table data-testid="catalog">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('common:unique')}</Table.Th>
                <Table.Th>{t('program:program_code')}</Table.Th>
                <Table.Th style={{ minWidth: '300px' }}>{t('course:course_name')}</Table.Th>
                <Table.Th>{t('course:credit')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.map((course) => (
                <Table.Tr key={course.id} data-testid={`catalog/${course.id}`}>
                  <Table.Td className="text-primaryTextColor" data-testid="courseCode">
                    {course.code}
                  </Table.Td>
                  <Table.Td
                    title={course.programCode}
                    className="text-primaryTextColor dark:text-primaryTextColor"
                    data-testid="programCode"
                  >
                    {shortenString(course.programCode, 50)}
                  </Table.Td>
                  <Table.Td className="text-primaryBlueLink dark:text-primaryTextColor">
                    <Link
                      className="text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                      to={`/${locale}/student/courses/${course.id}/groups`}
                    >
                      {course.name}
                    </Link>
                  </Table.Td>
                  <Table.Td className="text-primaryTextColor" data-testid="courseCredits">
                    {course.credits}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
          <PaginationBottom pagination={meta.pagination} />
        </>
      ) : (
        <NoContent header={t('error:courses_not_found')} image={Warning} subHeader={t('course:empty_catalog')} />
      )}
    </Section>
  )
}
