import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'

import * as Table from 'src/tailwind/components/Table'
import NoContent from 'src/views/components/NoContent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentBillingDepositsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('billing:deposits_financial_information')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const userState = useUserState()
  const { data: deposits } = useApi({
    endpoint: Api.getStudentBillingDeposits,
    params: React.useMemo(() => ({ headers }), [headers]),
    shouldFetch: userState.data?.regime.functionalIsLimited === false,
  })

  if (deposits == null || deposits.length === 0) {
    return <NoContent header={t('error:deposits_not_found')} image={Warning} marginTop="5" />
  }

  return (
    <Table.Table className="w-full">
      <Table.Thead>
        <Table.Tr>
          <Table.Th scope="col" className="px-5 py-6 text-left text-title font-bold text-black">
            {t('faculty:faculty')}
          </Table.Th>
          <Table.Th scope="col" className="px-5 py-6 text-left text-title font-bold text-black">
            {t('billing:amount')}
          </Table.Th>
          <Table.Th scope="col" className="px-5 py-6 text-left text-title font-bold text-black">
            {t('billing:deposit_date')}
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {deposits.map((deposit, key) => (
          <Table.Tr
            key={key}
            className="pointer-events-none border-t border-borderColor"
            data-testid={`deposit/${key}`}
          >
            <Table.Td className="whitespace-nowrap px-5 py-6">{deposit.facultyName}</Table.Td>
            <Table.Td className="whitespace-nowrap px-5 py-6">{deposit.amount} ₾ </Table.Td>
            <Table.Td className="whitespace-nowrap px-5 py-6">{deposit.date}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table.Table>
  )
}
