export function Draft(): JSX.Element {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_3)">
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill="#808080"
        />
        <path
          d="M4.41667 3.41667V7.58333H7.33333V4.42259L6.32741 3.41667H4.41667ZM4.41667 3H6.5L7.75 4.25V7.58333C7.75 7.81345 7.56345 8 7.33333 8H4.41667C4.18655 8 4 7.81345 4 7.58333V3.41667C4 3.18655 4.18655 3 4.41667 3Z"
          fill="white"
        />
        <path
          d="M6.49999 3.58925V4.25H7.16074L6.49999 3.58925ZM6.08333 3H6.49999L7.74999 4.25V4.66667H6.08333V3Z"
          fill="white"
        />
        <path
          d="M5.45834 4.04166H5.04168C4.92662 4.04166 4.83334 4.13493 4.83334 4.24999C4.83334 4.36505 4.92662 4.45832 5.04168 4.45832H5.45834C5.5734 4.45832 5.66668 4.36505 5.66668 4.24999C5.66668 4.13493 5.5734 4.04166 5.45834 4.04166Z"
          fill="white"
        />
        <path
          d="M6.70834 4.875H5.04168C4.92662 4.875 4.83334 4.96827 4.83334 5.08333C4.83334 5.19839 4.92662 5.29167 5.04168 5.29167H6.70834C6.8234 5.29167 6.91668 5.19839 6.91668 5.08333C6.91668 4.96827 6.8234 4.875 6.70834 4.875Z"
          fill="white"
        />
        <path
          d="M6.70834 5.70834H5.04168C4.92662 5.70834 4.83334 5.80162 4.83334 5.91668C4.83334 6.03174 4.92662 6.12501 5.04168 6.12501H6.70834C6.8234 6.12501 6.91668 6.03174 6.91668 5.91668C6.91668 5.80162 6.8234 5.70834 6.70834 5.70834Z"
          fill="white"
        />
        <path
          d="M6.70834 6.54167H5.04168C4.92662 6.54167 4.83334 6.63495 4.83334 6.75001C4.83334 6.86506 4.92662 6.95834 5.04168 6.95834H6.70834C6.8234 6.95834 6.91668 6.86506 6.91668 6.75001C6.91668 6.63495 6.8234 6.54167 6.70834 6.54167Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_3">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
