import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { ArrowBack } from 'src/assets/icons/customIcons/ArrowBack'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { PdfIcon } from 'src/assets/icons/customIcons/Pdf'
import { Recommendations } from 'src/assets/icons/customIcons/recommendation'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentRecommendationPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('recommendation:recommendation')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const { id } = useParams()
  const { pathname } = useLocation()
  const result = useApi({
    endpoint: Api.getStudentRecommendation,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })
  const recommendation = result.data

  const breadcrumbsItems = [
    { page: `${t('recommendation:recommendations')}`, path: `/${locale}/student/recommendations` },
    { page: recommendation.textFragment, path: pathname },
  ]

  return (
    <Section title={t('recommendation:recommendation')} icon={<Recommendations />} breadcrubms={breadcrumbsItems}>
      <div>
        <div className="mb-9 flex w-full justify-end sm:inline-block xxs:inline-block xs:inline-block">
          <div className="flex justify-between">
            <a
              href={`/${locale}/student/recommendations/${recommendation.id}/export`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center rounded-[18px] p-[10px] hover:bg-card sm:pl-0 xxs:pl-0 xs:pl-0"
              data-testid="pdfLink"
            >
              <div className="w-[18px]">
                <PdfIcon />
              </div>
              <span className="ml-2 text-xs text-lightPrimaryIconText">{t('common:export')}</span>
            </a>
            <Link
              className="ml-2 flex items-center justify-center rounded-[18px] p-[10px] text-xs text-lightPrimaryIconText hover:bg-card"
              to={`/${locale}/student/recommendations/`}
              data-testid="backLink"
            >
              <ArrowBack /> <span className="ml-2">{t('common:back')}</span>
            </Link>
          </div>
        </div>
        <div className="flex text-caption">
          <ClockIcon />
          <p className="ml-2" data-testid="date">
            {format(parseISO(recommendation.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
          </p>
        </div>
      </div>
      <div className="mt-[33px]">
        <div className="border-b-DEFAULT border-borderGrey pb-3 text-primaryTextColor" data-testid="text">
          <span className="text-bodyText" dangerouslySetInnerHTML={{ __html: recommendation.text }} />
        </div>
        <div className="mb-6 mt-3">
          <span className="mr-2 text-caption text-primaryTextColor">{t('common:author')}:</span>
          <span className="text-bodyText text-primaryTextColor" data-testid="author">
            {recommendation.author.fullName}
          </span>
        </div>
      </div>
    </Section>
  )
}
