import { t } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import { SurveyIcon } from 'src/assets/icons/customIcons/header-icons/Survey'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import GaugeChart from 'src/views/components/highCharts/GaugeChart'
import Loader from 'src/views/components/Loader'

export default function StudentPortfolioSectionsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:general')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const { pathname } = useLocation()
  const fetching = useApi({
    endpoint: Api.getStudentPortfolioSections,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const sections = fetching.data.data

  const breadcrumbsItems = [{ page: `${t('portfolio:dashboard')}`, path: pathname }]

  return (
    <Section title={`${t('portfolio:portfolio_dashboard')}`} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
      <>
        {sections.map((section) => (
          <div key={section.id} className="mt-6 text-primaryTextColor">
            <div className="mb-3 ml-3 flex items-center justify-between">
              <div className="flex items-center">
                <div className="text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]">
                  {section.name}
                </div>
                <div className="ml-3">
                  <Link to={`/${locale}/student/portfolio/sections/${section.id}/works`}>
                    <Button variant="minimal" className="flex !h-[24px] items-center !px-1">
                      <span className="!text-xs !text-captionColor"> {t('portfolio:show_details')}</span>
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="flex items-center">
                <div className="ml-3 flex items-center">
                  <SurveyIcon />
                </div>
                <div className="ml-2 flex items-center !text-xs !text-captionColor">
                  <div
                    className={`font-bold ${
                      section.totalProgress?.score !== undefined &&
                      section.totalProgress?.score > section.minScore &&
                      (section.competencies || []).some((c) => (c.progress?.score ?? 0) >= (c.minScore ?? 0))
                        ? 'text-primaryGreen'
                        : 'text-primaryYellow'
                    }`}
                  >
                    {`${section.totalProgress?.score ?? 0}`}
                  </div>
                  <span className="ml-1">{t('person:points')}</span>
                </div>
              </div>
            </div>

            <Table.Table className="min-w-[768px]">
              <Table.Thead>
                <Table.Tr className="w-full">
                  {section.workType !== 'FORM_2' ? (
                    section.competencies?.map((competency) => (
                      <Table.Th key={competency.id} className="!text-center">
                        <div> {`${competency.name}`}</div>
                        <div className="text-xs !font-light">
                          {`(${t('portfolio:portfolio_min')} ${competency.minScore} - ${t('portfolio:portfolio_max')} ${competency.maxScore})`}
                        </div>
                      </Table.Th>
                    ))
                  ) : (
                    <Table.Th className="!text-center">
                      {t('portfolio:total_rate')}
                      <div className="text-xs !font-light">
                        {''}
                        {`(${t('portfolio:portfolio_min')} ${section.minScore} - ${t('portfolio:portfolio_max')} ${section.maxScore})`}
                      </div>
                    </Table.Th>
                  )}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  {section.workType == 'FORM_2' ? (
                    <Table.Td className="!text-center">
                      <GaugeChart
                        minLimit={[section.minScore]}
                        score={section.totalProgress?.score ?? 0}
                        max={section.maxScore}
                      />
                    </Table.Td>
                  ) : (
                    section.competencies?.map((competency) => {
                      return (
                        <Table.Td key={competency.id} className="text-center">
                          <div className="flex items-center justify-center">
                            <div className="w-[100px] rounded-full">
                              <GaugeChart
                                minLimit={[competency.minScore ?? 0]}
                                score={competency.progress?.score ?? 0}
                                max={competency.maxScore ?? 0}
                              />
                            </div>
                          </div>
                        </Table.Td>
                      )
                    })
                  )}
                </Table.Tr>
              </Table.Tbody>
            </Table.Table>
          </div>
        ))}
      </>
    </Section>
  )
}
