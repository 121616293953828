import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'

export default function StudentPortfolioSectionScoresPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:portfolio')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { id } = useParams()
  const t = useTranslatable()

  const { data: charts, isValidating: chartsPending } = useApi({
    endpoint: Api.getStudentPortfolioSectionCharts,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
    suspense: false,
  })

  return (
    <Section>
      {charts?.data != null && charts.data?.length > 0 ? (
        <Table.Table className="min-w-[768px]">
          <Table.Thead>
            <Table.Tr className="w-full">
              <Table.Td className="max-w-[100px]"></Table.Td>
              {charts.data[0]?.historyProgress?.semesters.map((sems, index) => (
                <Table.Td key={index} className="text-center">
                  {`${t('common:semester')} ${sems.semester}`}
                </Table.Td>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {charts.data.map((item, index) => (
              <Table.Tr key={index}>
                <Table.Td className="max-w-[100px]">{item.name}</Table.Td>
                {item.historyProgress?.semesters.map((semScores, index) => (
                  <Table.Td key={index} className="text-center">
                    {semScores.score}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.Table>
      ) : chartsPending ? (
        <Loader className="m-auto flex" />
      ) : (
        <NoContent
          header={t('error:records_not_found')}
          image={Warning}
          subHeader={t('course:for_request_recommendation')}
        />
      )}
    </Section>
  )
}
