import classnames from 'classnames'
import { useFormikContext } from 'formik'
import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { formatDate, shortenString } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom } from 'src/views/components/Pagination'
import DateInput from 'src/views/components/forms/formik/DateInput'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'

interface ModalProps {
  setIsModalOpen: () => void
  group: Api.Group | null
}
interface FormikValues {
  startDate: string
  endDate: string
  day: string
}

export function StudentCourseGroupsScheduleModal({ setIsModalOpen, group }: ModalProps): JSX.Element {
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = searchParams.get('page') ?? '1'
  const perPage = searchParams.get('perPage') ?? '10'
  const { id: courseId } = useParams()
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [day, setDay] = React.useState('')
  const locale = useLocale()
  const t = useTranslatable()
  const formik = useFormikContext<FormikValues>()
  const theme = useTheme()

  const weekDays = [
    { label: t('common:day'), value: '0' },
    { label: t('calendar:monday'), value: '1' },
    { label: t('calendar:tuesday'), value: '2' },
    { label: t('calendar:wednesday'), value: '3' },
    { label: t('calendar:thursday'), value: '4' },
    { label: t('calendar:friday'), value: '5' },
    { label: t('calendar:saturday'), value: '6' },
    { label: t('calendar:sunday'), value: '7' },
  ]
  const clearFunction = () => {
    setDay('')
    setStartDate('')
    setEndDate('')
    setSearchParams()
    formik.resetForm()
  }

  const schedule: APIResponse<Api.getStudentCourseGroupScheduleOk | null, Api.getStudentCourseGroupScheduleErr> =
    useApi({
      endpoint: Api.getStudentCourseGroupSchedule,
      params: React.useMemo(
        () => ({
          headers,
          args: {
            id: courseId!,
            groupId: group!.id,
          },
          query: {
            filters: {
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              day: day,
            },
            page: parseInt(page),
            perPage: parseInt(perPage),
          },
        }),
        [headers, courseId, group, startDate, endDate, day, page, perPage]
      ),
      shouldFetch: group != null,
      suspense: false,
    })

  const submitAction = () => {
    const values = formik.values
    setStartDate(formatDate(values.startDate) || ''),
      setEndDate(formatDate(values.endDate) || ''),
      setDay(values.day.toString()),
      setSearchParams()
  }
  return (
    <Modal
      isModalOpen={group != null}
      footer={
        (schedule.data?.data.length != null &&
          schedule.data?.data.length > 0 &&
          schedule.data?.meta.pagination != null && <PaginationBottom pagination={schedule.data.meta.pagination} />) ||
        undefined
      }
      onClose={() => {
        clearFunction()
        setIsModalOpen()
      }}
      title={group?.name}
      className="group-modal"
      testId="groupScheduleModal"
    >
      <>
        <FormError />
        <div className="group-filter-box flex justify-end">
          <div className="date-box mr-0 flex">
            <DateInput
              className="max-w-[200px]"
              labelAnimation
              required
              name={`startDate`}
              label={t('common:start_date')}
            />
            <DateInput
              className="max-w-[200px]"
              labelAnimation
              required
              name={`endDate`}
              label={t('common:start_date')}
            />
          </div>
          <SelectInput placeholder={t('common:day')} name="day" options={weekDays} />

          <Button
            variant="outline"
            onClick={clearFunction}
            className="mr-2 border-none opacity-60"
            data-testid="clearButton"
          >
            {t('common:clean')}
          </Button>
          <Button onClick={submitAction} variant={'submit'} type="submit" data-testid="searchButton">
            {t('common:search')}
          </Button>
        </div>
      </>
      {schedule.error != null ? (
        <div className="flex min-h-[50vh] items-center justify-center overflow-auto">
          <NoContent
            image={Warning}
            header={t('error:an_error_occurred')}
            subHeader={(schedule.error as { message: '' }).message ?? ''}
          />
        </div>
      ) : schedule.data?.data == null ? (
        <div className="flex min-h-[50vh] items-center justify-center overflow-auto">
          <Loader />
        </div>
      ) : schedule.data.data.length === 0 ? (
        <div className="flex min-h-[50vh] items-center justify-center overflow-auto">
          <NoContent
            header={t('error:records_not_found')}
            image={Warning}
            subHeader={t('schedule:schedule_is_empty')}
          />
        </div>
      ) : (
        <Table.Tr className="schedule-table">
          <Table.Td colSpan={6} className="bg-card !p-0">
            <Table.Table className="!rounded-none">
              <Table.Thead>
                <Table.Tr className="border-t-0">
                  <Table.Th className="w-[16%] !py-[10px] !pl-[75px]">{t('common:day')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:time')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:room')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:lecture_type')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('lecturer:lecturers')}</Table.Th>
                  <Table.Th className="w-[16%] !py-[10px]">{t('common:information')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {schedule.data?.data.map((schedule, index) => (
                  <React.Fragment key={index}>
                    <Table.Tr
                      className={classnames({
                        '!bg-primaryRed/30': schedule.scheduleConflictMessage,
                      })}
                      data-tooltip-id={`${group?.id}${index}`}
                      data-testid={`schedule-${index}`}
                    >
                      <Table.Td className="!py-[10px] !pl-[75px]" data-testid="dayColumn">
                        {schedule.day}
                        <span className="ml-[5px] font-normal text-captionColor">({schedule.date})</span>
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="timeColumn">
                        {schedule.startTime} - {schedule.endTime}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="locationColumn">
                        {schedule.locationName ?? schedule.lectureFormat}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-testid="lectureTypeColumn">
                        {schedule.lectureType}
                      </Table.Td>
                      <Table.Td className="!py-[10px]">
                        {schedule.lecturers?.map((lecturer, index) => (
                          <Link
                            key={index}
                            to={`/${locale}/users/${lecturer.uid}`}
                            className="ml-2 whitespace-nowrap align-middle hover:underline"
                            data-testid={`lecturerName-${lecturer.uid}`}
                          >
                            {lecturer.fullName}
                          </Link>
                        ))}
                      </Table.Td>
                      <Table.Td className="!py-[10px]" data-tooltip-id={'info'} data-testid="infoColumn">
                        {shortenString(schedule.info ?? '', 30)}
                      </Table.Td>
                    </Table.Tr>
                    <Tooltip
                      id="info"
                      place="left"
                      noArrow
                      // variant={theme === 'dark' ? 'dark' : 'light'}
                      className="grup-tooltip max-w-[300px]"
                      opacity={100}
                    >
                      {schedule.info}
                    </Tooltip>
                    {schedule.scheduleConflictMessage != null && (
                      <Tooltip
                        id={`${group?.id}${index}`}
                        place="top"
                        variant={theme === 'dark' ? 'dark' : 'light'}
                        className="grup-tooltip mb-1 max-h-[40px] p-1"
                        opacity={100}
                      >
                        {schedule.scheduleConflictMessage}
                      </Tooltip>
                    )}
                  </React.Fragment>
                ))}
              </Table.Tbody>
            </Table.Table>
          </Table.Td>
        </Table.Tr>
      )}
    </Modal>
  )
}
